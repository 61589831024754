import React, { Component } from 'react';
import globalVar from '../../globalVar';
import './Dashboard.css';
import { supabase } from '../../client';
import {useNavigate} from 'react-router-dom';

const Dashboard = () => {
    let navigate = useNavigate()
    var deleteConfirmation = 5
    function goPassword(){
        navigate('/newpassword')
    }
    function goDelete(){
        navigate('/deleteaccount')
    }
    async function goOut(){
        try {
            const { error } = await supabase.auth.signOut()
            navigate('/')
        } catch (error) {
            alert(error)
        }
        
    }
    return (
        <div className='welcomeWrapper'>
            <h1 className='welcome'>Welcome, {globalVar.playerData.username}</h1>
            <button className='settingButton' onClick={goPassword}> Change Password</button>

            <button className='settingButton' onClick={goDelete}> Delete Account </button>
            
            <button className='settingButton' onClick={goOut}> Log Out</button>
        </div>
      
    )
}

export default Dashboard
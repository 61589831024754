
// import LoginForm from './Comps/Pages/LoginForm';
import React from 'react';
import LoginForm from './Comps/Pages/LoginForm'
import Dashboard from './Comps/Pages/Dashboard';
import NewPassword from './Comps/Pages/NewPassword';
import DeleteAcount from './Comps/Pages/DeleteAcount';
import ForgotPassword from './Comps/Pages/ForgotPassword';
import {Routes,Route, Router} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={'/'} element={<LoginForm />} />
        <Route path={'/dashboard'} element={<Dashboard />} />
        <Route path={'/newpassword'} element={<NewPassword />} />
        <Route path={'/deleteaccount'} element={<DeleteAcount />} />
        <Route path={'/forgotpassword'} element={<ForgotPassword />} />
      </Routes>
      
    </div>
  );
}

export default App;

import React from 'react'
import './basic.css'
import globalVar from '../../globalVar'
import { supabase } from '../../client';
import { useNavigate } from 'react-router-dom'
const DeleteAcount = () => {
    let navigate = useNavigate()
    var confirmCount = 0
    function confirmDeletion(event){
        confirmCount += 1
        event.target.disabled = true
        if (confirmCount >=3) {
            goodBye()
        }
    }

    async function goodBye(){
        try {
            const { data, error } = await supabase.rpc('deleteuser',{deleteuid:globalVar.authData.user.id})
            navigate('/')
        } catch (error) {
         alert(error)   
        }
    }
  return (
    <div className='wrapper'>
        <h1>Account will permanently deleted.</h1>
        <h1>CANNOT BE RECOVERED</h1>

        <button className='deleteButton' onClick={confirmDeletion}> CONFIRM </button>
        <button className='deleteButton' onClick={confirmDeletion}> CONFIRM </button>
        <button className='deleteButton' onClick={confirmDeletion}> CONFIRM </button>
    </div>
    
  )
}

export default DeleteAcount

import React, { useState } from 'react'
import './basic.css'
import { FaUserAstronaut } from "react-icons/fa6";
import { RiLockPasswordFill } from "react-icons/ri";
import { supabase } from '../../client';
import globalVar from '../../globalVar';
import {useNavigate} from 'react-router-dom';
const ForgotPassword = () => {
    let navigate = useNavigate()
    const [formData,setFormData] = useState({
        email:''
    })
    const [notif,setNotif] = useState('')
    function changeFormData(event){
        setFormData((prevFormData)=>{
            return{
                ...prevFormData,
                [event.target.name]:event.target.value
            }
        })
    }
    async function resetPassword(e){
        e.preventDefault()
        try {
            
            const { data, error } = await supabase.auth.resetPasswordForEmail(formData.email,{redirectTo:'https://farmton-indol.vercel.app/newpassword'})
            if (error) throw error
            setNotif('Reset Link Has Been Sent!')
        }    
        catch (error) {
            alert(error)
        }
    }
    const subscription = supabase.auth.onAuthStateChange((event, session) => {
        console.log(event, session)
        if (event === 'PASSWORD_RECOVERY') {
            navigate('/newpassword')
          } 
        })
      
  return (
    <div className='wrapper'>
        <form onSubmit={resetPassword}>
            <h1>Reset Password</h1>
            <div className='input-box'> 
                <input type='email' name='email' onChange={changeFormData}
                placeholder='Input Email...' /> <FaUserAstronaut className='icon'/>
            </div>
            <p>{notif}</p>
            <button type='submit'>Reset Password</button>


        </form>

    </div>
  )
}

export default ForgotPassword

import React, { useState } from 'react'
import './LoginForm.css'
import { FaUserAstronaut } from "react-icons/fa6";
import { RiLockPasswordFill } from "react-icons/ri";
import { supabase } from '../../client';
import globalVar from '../../globalVar';
import {useNavigate} from 'react-router-dom';
const LoginForm = () => {
let navigate = useNavigate()
    const [formData,setFormData] = useState({
        email:'',password:''
    })

    function changeFormData(event){
        setFormData((prevFormData)=>{
            return{
                ...prevFormData,
                [event.target.name]:event.target.value
            }
        })
    }
    console.log(formData)

    async function logEmIn(e){
        e.preventDefault()
        try {
            const { data, error } = await supabase.auth.signInWithPassword({
                email: formData.email,
                password: formData.password
            })
            if (error) throw error
            globalVar.authData = data
            console.log(data.user.id)
            get_playerData()
        }    
        catch (error) {
            alert(error)
        }
    }
    
    async function get_playerData(){
        try {
            const {data, error } = await supabase
            .from('player_data')
            .select().eq('uid',globalVar.authData.user.id)
            
            globalVar.playerData = data[0]
            console.log(data[0].username)
            navigate('/dashboard')
        } catch (error2) {
            alert(error2)
        }
    }
    function goForgot(){
        navigate('/forgotpassword')
    }

  return (
    <div className='wrapper'>
        <form onSubmit={logEmIn}>
            <h1>Login Form</h1>
            <div className='input-box'> 
                <input type='email' name='email' onChange={changeFormData}
                placeholder='Input Email...' /> <FaUserAstronaut className='icon'/>
            </div>
            <div className='input-box'> 
                <input type='password' name='password' onChange={changeFormData}
                placeholder='Input Password...' /> <RiLockPasswordFill className='icon' />
            </div>
            <label />
            <div className='remember-forgot'>
                <label className='forgotPassword'>
                    <a onClick={goForgot}href="#">Forgot password ?</a>
                </label>
            </div>
            <p></p>
            <button type='submit'>Login</button>


        </form>

    </div>
  )
}

export default LoginForm
import React, { useEffect, useState } from 'react'
import { RiLockPasswordFill } from "react-icons/ri";
import './basic.css'
import { supabase } from '../../client';
import { useNavigate } from 'react-router-dom';
const NewPassword = () => {
    let navigate = useNavigate()
    const [passwordStatus,setPWStatus] = useState(true);
    const [passwordX1,pw1] = useState(true)
    const [formData,setFormData] = useState({
        password1:'',password2:''
    })
    function changepw(event){
        pw1(event.target.value)
        console.log(passwordX1,"HUH ?")
    }
    useEffect(
        ()=>{
            console.log(formData)
            if (formData.password1 == formData.password2 && formData.password1.length >= 8){
                setPWStatus(false)
                console.log(passwordStatus,"HUH ?")
            }
            else{
                setPWStatus(true)
            }
        },[formData]
    )




    async function changeFormData(event){
        
        setFormData((prevFormData)=>{
            return{
                ...prevFormData,
                [event.target.name]:event.target.value
            }
        })
        console.log(formData)
        
    }




    async function changePassword(){
        try {
            const { data, error } = await supabase.auth.updateUser({
                password: formData.password1
              })
              navigate('/')
              
        } catch (error) {
            alert(error)
        }
    }
    

  return (
    <div className='wrapper'>
        <h1>New Password</h1>
            <div className='input-box'> 
                <input type='password' name='password1' onChange={changeFormData}
                placeholder='New Password...' /> <RiLockPasswordFill className='icon' />
            </div>
            <div className='input-box'> 
                <input type='password' name='password2' onChange={changeFormData}
                placeholder='Confirmation...' /> <RiLockPasswordFill className='icon' />
            </div>
            <button disabled={passwordStatus} onClick={changePassword}>Confirm Changes</button>
    </div>
  )
}

export default NewPassword